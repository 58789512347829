var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pl-12 pr-12", attrs: { fluid: "" } },
    [
      _c(
        "ValidationObserver",
        { ref: "observer" },
        [
          _c(
            "v-layout",
            { attrs: { column: "", wrap: "" } },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "font-weight-black pl-3 mb-4",
                  staticStyle: { "font-size": "32px" }
                },
                [_vm._v(_vm._s(_vm.$t("link.editAgencyInfoForIndividual")))]
              ),
              _c("v-row", { staticClass: "ml-8 mb-4" }, [
                _c("p", { staticClass: "title font-weight-black" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("label.editUserInfo.agencyCode")) +
                      "\n        "
                  )
                ]),
                _c("p", { staticClass: "title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.loginUserInfo.agencyCode) +
                      "\n        "
                  )
                ])
              ]),
              _c("v-row", { staticClass: "ml-8 mb-4" }, [
                _c("p", { staticClass: "title font-weight-black" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("label.editUserInfo.agencyName")) +
                      "\n        "
                  )
                ]),
                _c("p", { staticClass: "title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.loginUserInfo.agencyName) +
                      "\n        "
                  )
                ])
              ]),
              _c("v-row", { staticClass: "ml-8 mb-4" }, [
                _c("p", { staticClass: "title font-weight-black" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("label.editUserInfo.salesCode")) +
                      "\n        "
                  )
                ]),
                _c("p", { staticClass: "title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.loginUserInfo.salesCode) +
                      "\n        "
                  )
                ])
              ]),
              _c("v-row", { staticClass: "ml-8" }, [
                _c("p", { staticClass: "title font-weight-black" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("label.editUserInfo.recruiterId")) +
                      "\n        "
                  )
                ]),
                _c("p", { staticClass: "title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.loginUserInfo.recruiterId) +
                      "\n        "
                  )
                ])
              ]),
              _c("v-row", { staticClass: "ml-8 mb-4" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "description.editAgencyInfoForIndividual.recruiterId"
                        )
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _c("v-row", { staticClass: "ml-8" }, [
                _c(
                  "p",
                  { staticClass: "title my-auto font-weight-black" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("label.editUserInfo.chargePersonName")) +
                        "\n          "
                    ),
                    _c(
                      "v-chip",
                      {
                        staticClass: "ma-2 font-weight-black",
                        attrs: { color: "next", dark: "", label: "", small: "" }
                      },
                      [_vm._v(_vm._s(_vm.$t("label.required")))]
                    )
                  ],
                  1
                )
              ]),
              _c("v-row", { staticClass: "ml-8" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "description.editAgencyInfoForIndividual.warning"
                        )
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _c(
                "v-row",
                { staticClass: "ml-8 mb-4" },
                [
                  _c(
                    "v-flex",
                    { attrs: { md4: "" } },
                    [
                      _c("ValidationProvider", {
                        attrs: {
                          rules:
                            "required|max:" + _vm.MaxLength.ChargePersonName,
                          immediate: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var errors = ref.errors
                              return [
                                _c("v-text-field", {
                                  attrs: { "hide-details": "", outlined: true },
                                  model: {
                                    value: _vm.loginUserInfo.chargePersonName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.loginUserInfo,
                                        "chargePersonName",
                                        $$v
                                      )
                                    },
                                    expression: "loginUserInfo.chargePersonName"
                                  }
                                }),
                                _c("ValidationError", {
                                  staticClass: "ma-0",
                                  attrs: { error: errors[0] }
                                })
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-row", { staticClass: "ml-8" }, [
                _c(
                  "p",
                  { staticClass: "title my-auto font-weight-black" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("label.editUserInfo.chargePersonPhoneNumber")
                        ) +
                        "\n          "
                    ),
                    _c(
                      "v-chip",
                      {
                        staticClass: "ma-2 font-weight-black",
                        attrs: { color: "next", dark: "", label: "", small: "" }
                      },
                      [_vm._v(_vm._s(_vm.$t("label.required")))]
                    )
                  ],
                  1
                )
              ]),
              _c("v-row", { staticClass: "ml-8" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "description.editAgencyInfoForIndividual.warning"
                        )
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _c(
                "v-row",
                { staticClass: "ml-8 mb-4" },
                [
                  _c(
                    "v-flex",
                    { attrs: { md4: "" } },
                    [
                      _c("ValidationProvider", {
                        attrs: {
                          rules: "required|noSpace|telNumberLength",
                          immediate: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var errors = ref.errors
                              return [
                                _c("v-text-field", {
                                  attrs: { "hide-details": "", outlined: true },
                                  on: {
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.onConvertToHalfWidth(
                                        "chargePersonPhoneNumber"
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.onConvertToHalfWidth(
                                        "chargePersonPhoneNumber"
                                      )
                                    },
                                    compositionend: function($event) {
                                      return _vm.onConvertToHalfWidth(
                                        "chargePersonPhoneNumber"
                                      )
                                    }
                                  },
                                  model: {
                                    value:
                                      _vm.loginUserInfo.chargePersonPhoneNumber,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.loginUserInfo,
                                        "chargePersonPhoneNumber",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "loginUserInfo.chargePersonPhoneNumber"
                                  }
                                }),
                                _c("ValidationError", {
                                  staticClass: "ma-0",
                                  attrs: { error: errors[0] }
                                })
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "ml-8 mt-12" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "title mr-5",
                      staticStyle: { "font-size": "15px !important" },
                      attrs: { color: "back", width: "170px", dark: "" },
                      on: {
                        click: function($event) {
                          return _vm.onClickBackHome()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "title ml-5",
                      staticStyle: { "font-size": "15px !important" },
                      attrs: { color: "next", width: "170px", dark: "" },
                      on: {
                        click: function($event) {
                          return _vm.openConfirmDialog()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("button.update")))]
                  )
                ],
                1
              ),
              _c("CompletedDialog", {
                ref: "completedDialog",
                on: { onSuccess: _vm.onSuccessUpdateLoginUserInfo }
              }),
              _c("ErrorDialog", { ref: "errorDialog" }),
              _c("SimpleDialog", {
                attrs: {
                  showDialog: _vm.showConfirmDialog,
                  title: _vm.$t("title.agency.userEdit"),
                  text: null,
                  subText: null,
                  multiLineText: null,
                  confirmText: _vm.$t("description.updateLoginUserInfo"),
                  negativeButtonTitle: _vm.$t("button.cancel"),
                  positiveButtonTitle: _vm.$t("button.update"),
                  onClickNegativeButton: _vm.closeConfirmDialog,
                  onClickPositiveButton: _vm.onClickUpdate
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }