<template>
  <v-container fluid class="pl-12 pr-12">
    <ValidationObserver ref="observer">
      <v-layout column wrap>
        <v-card-title
          class="font-weight-black pl-3 mb-4"
          style="font-size:32px"
          >{{ $t('link.editAgencyInfoForIndividual') }}</v-card-title
        >
        <!-- 代理店コード（ユーザー） -->
        <v-row class="ml-8 mb-4">
          <p class="title font-weight-black">
            {{ $t('label.editUserInfo.agencyCode') }}
          </p>
          <p class="title">
            {{ loginUserInfo.agencyCode }}
          </p>
        </v-row>
        <!-- 代理店名（ユーザー） -->
        <v-row class="ml-8 mb-4">
          <p class="title font-weight-black">
            {{ $t('label.editUserInfo.agencyName') }}
          </p>
          <p class="title">
            {{ loginUserInfo.agencyName }}
          </p>
        </v-row>
        <!-- 部担コード -->
        <v-row class="ml-8 mb-4">
          <p class="title font-weight-black">
            {{ $t('label.editUserInfo.salesCode') }}
          </p>
          <p class="title">
            {{ loginUserInfo.salesCode }}
          </p>
        </v-row>
        <!-- 募集人ID -->
        <v-row class="ml-8">
          <p class="title font-weight-black">
            {{ $t('label.editUserInfo.recruiterId') }}
          </p>
          <p class="title">
            {{ loginUserInfo.recruiterId }}
          </p>
        </v-row>
        <v-row class="ml-8 mb-4">
          <p>
            {{ $t('description.editAgencyInfoForIndividual.recruiterId') }}
          </p>
        </v-row>
        <!-- 担当者名 -->
        <v-row class="ml-8">
          <p class="title my-auto font-weight-black">
            {{ $t('label.editUserInfo.chargePersonName') }}
            <v-chip
              class="ma-2 font-weight-black"
              color="next"
              dark
              label
              small
              >{{ $t('label.required') }}</v-chip
            >
          </p>
        </v-row>
        <v-row class="ml-8">
          <p>
            {{ $t('description.editAgencyInfoForIndividual.warning') }}
          </p>
        </v-row>
        <v-row class="ml-8 mb-4">
          <v-flex md4>
            <ValidationProvider
              :rules="`required|max:${MaxLength.ChargePersonName}`"
              immediate
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="loginUserInfo.chargePersonName"
                hide-details
                :outlined="true"
              />
              <ValidationError class="ma-0" :error="errors[0]" />
            </ValidationProvider>
          </v-flex>
        </v-row>
        <!-- 担当者電話番号 -->
        <v-row class="ml-8">
          <p class="title my-auto font-weight-black">
            {{ $t('label.editUserInfo.chargePersonPhoneNumber') }}
            <v-chip
              class="ma-2 font-weight-black"
              color="next"
              dark
              label
              small
              >{{ $t('label.required') }}</v-chip
            >
          </p>
        </v-row>
        <v-row class="ml-8">
          <p>
            {{ $t('description.editAgencyInfoForIndividual.warning') }}
          </p>
        </v-row>
        <v-row class="ml-8 mb-4">
          <v-flex md4>
            <ValidationProvider
              :rules="'required|noSpace|telNumberLength'"
              immediate
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="loginUserInfo.chargePersonPhoneNumber"
                hide-details
                :outlined="true"
                @keyup.enter="onConvertToHalfWidth('chargePersonPhoneNumber')"
                @blur="onConvertToHalfWidth('chargePersonPhoneNumber')"
                @compositionend="onConvertToHalfWidth('chargePersonPhoneNumber')"
              />
              <ValidationError class="ma-0" :error="errors[0]" />
            </ValidationProvider>
          </v-flex>
        </v-row>
        <v-row class="ml-8 mt-12">
          <v-btn
            class="title mr-5"
            color="back"
            width="170px"
            style="font-size:15px !important"
            dark
            @click="onClickBackHome()"
            >{{ $t('button.cancel') }}</v-btn
          >
          <v-btn
            class="title ml-5"
            color="next"
            width="170px"
            style="font-size:15px !important"
            dark
            @click="openConfirmDialog()"
            >{{ $t('button.update') }}</v-btn
          >
        </v-row>
        <CompletedDialog
          @onSuccess="onSuccessUpdateLoginUserInfo"
          ref="completedDialog"
        ></CompletedDialog>
        <ErrorDialog ref="errorDialog"></ErrorDialog>
        <!-- 更新確認 -->
        <SimpleDialog
          :showDialog="showConfirmDialog"
          :title="$t('title.agency.userEdit')"
          :text="null"
          :subText="null"
          :multiLineText="null"
          :confirmText="$t('description.updateLoginUserInfo')"
          :negativeButtonTitle="$t('button.cancel')"
          :positiveButtonTitle="$t('button.update')"
          :onClickNegativeButton="closeConfirmDialog"
          :onClickPositiveButton="onClickUpdate"
        />
      </v-layout>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import CompletedDialog from '@/components/organisms/agency/CompletedDialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import SimpleDialog from '@/components/organisms/agency/SimpleDialog';
import { MaxLength } from '@/lib/const';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ValidationError from '@/components/organisms/common/ValidationError';
import '@/lib/veeValidation';
import { getLoginUser, putLoginUser } from '@/apis/agency/users';

export default {
  name: 'EditAgencyInfoForIndividual',
  components: {
    CompletedDialog,
    ErrorDialog,
    SimpleDialog,
    ValidationProvider,
    ValidationObserver,
    ValidationError,
  },
  data() {
    return {
      // ログインユーザー情報
      loginUserInfo: {},
      MaxLength: MaxLength,
      showConfirmDialog: false,
    };
  },
  computed: {},
  async mounted() {
    // ユーザーの取得
    await this.fetchUsers();
  },
  methods: {
    ...mapActions('user', ['setUserDetail']),

    async fetchUsers() {
      // ログインユーザー情報の取得
      const loginUserInfo = await getLoginUser().catch(() => {});
      this.$set(this, 'loginUserInfo', loginUserInfo);
    },

    onClickBackHome() {
      this.$router.push('/Home');
    },
    async openConfirmDialog() {
      // バリデーションチェック
      if (await this.$refs.observer.validate()) {
        this.$set(this, 'showConfirmDialog', true);
      }
    },
    closeConfirmDialog() {
      this.$set(this, 'showConfirmDialog', false);
    },
    async onClickUpdate() {
      // 編集項目の保持
      const condition = {
        ...(this.loginUserInfo.chargePersonName && {
          chargePersonName: this.loginUserInfo.chargePersonName,
        }),
        ...(this.loginUserInfo.chargePersonPhoneNumber && {
          chargePersonPhoneNumber: this.loginUserInfo.chargePersonPhoneNumber,
        }),
        ...(this.loginUserInfo.chargePersonMail && {
          chargePersonMail: this.loginUserInfo.chargePersonMail,
        }),
      };
      // DB更新
      const result = await putLoginUser(condition).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.userUpdateError'),
          this.$t('error.userUpdateBadRequest')
        );
      });

      // ユーザーが編集できなかった場合、以降の処理を中止する
      if (!result) return;

      this.$set(this, 'showConfirmDialog', false);
      await this.fetchUsers();

      // ストア情報の更新
      const loginUserInfo = await getLoginUser().catch(() => {});
      this.setUserDetail(loginUserInfo);

      this.$refs.completedDialog.open(
        this.$t('title.agency.editUserInfoResult'),
        this.$t('success.userInfoUpdated')
      );
    },
    onSuccessUpdateLoginUserInfo() {
      this.$router.push('/Home');
    },
    onErrorUpdate() {
      this.$refs.errorDialog.open(
        this.$t('title.agency.editUserInfoResult'),
        this.$t('error.updateUserInfoFailed')
      );
    },
    onConvertToHalfWidth(key) {
      // 全角英数字を半角英数字に自動変換
      if (this.loginUserInfo[key])
        this.loginUserInfo[key] = this.loginUserInfo[key].replace(/[０-９]/g, function(s) {
          return String.fromCharCode(s.charCodeAt(0) - 65248);
        });
    },
  },
};
</script>
